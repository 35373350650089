<div class="card">
	<section class="row large">
		<div class="col-xs-3"></div>
		<div class="col-xs-6 col-sm-7">Happy Father's Day!</div>
	</section>

	{#if section > 0}
		<section class="row" in:fade>
			<div class="col-md-12">I can't wait to see you soon. Today though I get to tell you how much I love you.</div>
		</section>
	{/if}

	{#if section > 1}
		<section class="row" in:fade>
			<div class="col-md-12">You have always been there supporting me and cheering me on as I continue to walk through life with Christ.</div>
			<div class="col-md-12">Thank your for doing that.</div>
		</section>
	{/if}

	{#if section > 2}
		<section class="row" in:fade>
			<div class="col-md-12">You have been and continue to be a great example of a man following Christ to immitate.</div>
			<div class="col-md-12">"Brothers, join in immitating me, and keep your eyes on those who walk according to the example you have in us." - Phil 3:17</div>
		</section>
	{/if}

	{#if section > 3}
		<section class="row" in:fade>
			<div class="col-md-12">Especially as I've gotten the privelage to see you grow in your walk with him and share some of your struggles with me.</div>
		</section>
	{/if}

	{#if section > 4}
		<section class="row" in:fade>
			<div class="col-md-12">I look forward to seeing you soon and getting the chance to hike with you soon.</div>
		</section>
	{/if}

	{#if section > 5}
		<section class="row large" in:fade>
			<div class="col-xs-3"></div>
			<div class="col-xs-6 col-sm-7">Love, Brandon</div>
		</section>
	{/if}
</div>

<script>
	import { fade } from 'svelte/transition';
	import { onMount } from 'svelte';

	let section = 0;

	onMount(() => {
		document.addEventListener('click', () => {
			section++;
		});
	});
</script>

<style lang="scss">.card {
  margin-top: 1.5rem; }

section {
  font-size: 1.3rem;
  margin-top: 15px;
  margin-left: 8px;
  margin-right: 8px; }
  section:first-child {
    margin-top: 0; }
  section.large {
    font-weight: 600;
    font-size: 1.4rem; }
  section div {
    text-align: center; }

@media only screen and (min-width: 48rem) {
  section {
    font-size: 1.35rem;
    margin-left: 0;
    margin-right: 0; }
    section.large {
      font-size: 1.85rem; } }

/*# sourceMappingURL=Home.svelte.css.map */</style>
